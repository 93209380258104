<template lang="pug">
div
  .sidebar-layout__toolbar
    button.btn.btn--brand.mr-2(
      @click="modals.showFilterReportsModal = true")
      font-awesome-icon.mr-1(
        size="sm"
        icon="filter")
      span {{ 'actions.add_filter' | translate }}

    v-dropdown(
      auto-close)
      v-btn(
        v-if="!isMobile"
        medium
        :loading="loading"
        v-permission-hide="permissions.download_report")
          v-icon(
            position-left
            font-size="1"
            icon="file-download")
          span {{ 'actions.form_report' | translate }}
      template(slot="content")
        v-card(
          elevated
          overflow-hidden)
          v-list
            v-list-item(
              @click.native="getReport")
              | {{ 'pages.reports.type_1' | translate }}
            v-list-item(
              @click.native="getReportShort")
              | {{ 'pages.reports.type_2' | translate }}
            v-list-item(
              @click.native="getReportIngredients")
              | {{ 'pages.reports.storage' | translate }}
            v-list-item(
              @click.native="getReportProducts")
              | {{ 'pages.reports.products' | translate }}

  v-dialog(
    max-width="400"
    v-model="modals.showFilterReportsModal")
    filter-reports-modal(
      @close="modals.showFilterReportsModal = false")

  //- FOR DESKTOP AND TABLETS
  template(v-if="!isMobile")
    reports-table

  //- FOR MOBILE ONLY
  template(v-if="isMobile")
    reports-table-mobile

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FileSaver from 'file-saver'

import adaptationMixin from '@/mixins/adaptation.mixin'

import FilterReportsModal from './modals/FilterReportsModal'
import permissions from '@/util/permissions'

const ReportsTable = () => import('./new-version/ReportsTable')
const ReportsTableMobile = () => import('./old-version/ReportsTableMobile')

export default {
  name: 'FarmReports',

  mixins: [adaptationMixin],

  components: {
    ReportsTable,
    ReportsTableMobile,
    FilterReportsModal
  },

  data: () => ({
    modals: {
      showFilterReportsModal: false,
      showFormReportsModal: false
    },
    loading: false,
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'hasReportsFilters',
      'reportsFilters'
    ])
  },

  methods: {
    ...mapActions([
      'fetchReportsExport',
      'fetchReportsShort',
      'fetchReportsIngredients',
      'fetchReportsProducts'
    ]),

    async getReport () {
      this.loading = true
      const file = await this.fetchReportsExport()
      this.loading = false
      FileSaver.saveAs(file, `${this.$t('reports.files.full')}.xlsx`)
    },

    async getReportShort () {
      this.loading = true
      const file = await this.fetchReportsShort()
      this.loading = false
      FileSaver.saveAs(file, `${this.$t('reports.files.short')}.xlsx`)
    },

    async getReportIngredients () {
      this.loading = true
      const file = await this.fetchReportsIngredients()
      this.loading = false
      FileSaver.saveAs(file, `${this.$t('reports.files.storage')}.xlsx`)
    },

    async getReportProducts () {
      this.loading = true
      const file = await this.fetchReportsProducts()
      this.loading = false
      FileSaver.saveAs(file, `${this.$t('reports.files.products')}.xlsx`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
